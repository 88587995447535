import { NpsEnum } from "../../enum/nps.enum";

export const props = () => ({
  zendeskId: "cab743de-0681-4851-9630-98da2a6e3d80",
  apiUrl: "https://api-petrovina-prod.graodireto.com.br",
  logo: "graodireto",
  appTitle: "Barter Fácil",
  theme: "graodireto",
  companyLanguage: "default",
  keyCloakConfig: {
    url: "https://auth.graodireto.com.br",
    realm: "petrovina",
    clientId: "petrovina-front",
  },
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAIMidBodtwUqfr3TQgi5ecSAKz1rMMPZQ",
    authDomain: "barter-petrovina.firebaseapp.com",
    projectId: "barter-petrovina",
    storageBucket: "barter-petrovina.appspot.com",
    messagingSenderId: "676380012093",
    appId: "1:676380012093:web:228bb94a9ca360d2834454",
    measurementId: "G-J6YEVT7SW7",
  },
  hotjarConfig: "4946518",
  nps: {
    api: "https://api.graodireto.com.br/api",
    url: "https://graodireto.com.br/nps",
    key: "109d2ffc-e06b-4c6a-88c2-6a1b9095dc75",
  },
  npsSourceId: NpsEnum.EASY_BARTER_PETROVINA,
});
